:root {
  --primary: #23292e;
  --secondary: #1e2124;
  --light: #fafafd;
}
* {
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: var(--primary);
  color: var(--light);
}

body {
  color: var(--primary);
  background-color: var(--primary);
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji;
}

input {
  width: 2.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  margin: 0 0.75rem;
  background-color: transparent;
  border: 1px solid var(--secondary);
  border-radius: 5px;
  text-align: center;
}

.menu {
  padding: 2rem 2.25rem 1rem;
  margin: 0 auto;
  max-width: 50rem;
  background-color: var(--light);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

@media (min-width: 660px) {
  .menu {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.logo-wrap {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #414040;
  grid-column: 1 / 3;
}
h2 {
  font-size: 30px;
  margin: 0;
}
p {
  text-transform: uppercase;
  font-size: 12px;
}
.logo {
  width: 200px;
  margin: 0 auto 2rem;
  display: block;
  grid-column: 1 / 3;
}

@media (min-width: 660px) {
  .logo {
    margin-bottom: 0;
  }
}

.mains {
  grid-column: 1 / 2;
}

.mains-name {
  margin: 0;
  flex-grow: 1;
}

.mains-description {
  flex: 1 1 100%;
  margin: 0.75rem 0 0;
  color: var(--secondary);
}

.aside {
  grid-column: 2 / 3;
}

.extras {
  color: var(--secondary);
}

.extras + .extras {
  margin-top: 1.5rem;
}

@media (min-width: 660px) {
  .extras + .extras {
    margin-top: 2.5rem;
  }
}

.extras .menu-item {
  margin-bottom: 0.75rem;
}

.extras-heading {
  margin: 0 0 1.5rem;
  border-bottom: 1px solid var(--secondary);
}

.extras-name {
  flex-grow: 1;
}

.total {
  grid-column: 1 / 3;
  margin-top: 2.5rem;
  padding: 0.75rem 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  color: var(--secondary);
  background-color: var(--light);
  border-top: 1px solid var(--secondary);
  box-shadow: 0 -0.5rem 0.75rem 0.5rem var(--light);
}

@media (min-width: 660px) {
  .total {
    margin-top: 0;
  }
}

.total-title {
  margin: 0;
}

.total-price {
  font-size: 1.5rem;
}
